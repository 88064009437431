<template>
  <div class=" overflow-hidden">
    <v-btn :loading="btnLoading" small depressed style="" color="primary" class=" ma-2 pa-0" title="上传" width="80" min-width="80" height="30">
      <!-- accept=".xls,.xlsx" -->
      <input ref="upload" style="width:80px;height:30px; opacity:0;position:absolute;z-index:1" placeholder="" class=" cursor_pointer" multiple type="file" @change="fileChange">
      上传
    </v-btn>
    <div class=" overflow-auto">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width header-grey"
        hide-default-footer
        item-key="id"
        no-data-text="暂无查询数据"
        :loading="listLoading"
        disable-sort
      >
        <template
          v-slot:item.rowIndex="{index}"
        >{{ (index+1)+(search.pageSize*(search.pageNo-1)) }}</template>
        <template
          v-slot:item.fileName="{item}"
        >
          <div class=" font-overflow" style="max-width:500px;">{{ item.fileName }}</div>
        </template>
        <template v-slot:item.action="{item}">
          <div class=" flex-y-center">
            <v-icon color="primary" size="18" title="下载" class=" mr-1" @click="down(item.id)">mdi-arrow-down-bold-box-outline</v-icon>
            <v-icon color="primary" size="18" title="编辑" class=" mr-1" @click.stop="edit(item)">mdi-square-edit-outline</v-icon>
            <v-icon color="error" size="20" title="删除" @click="activeList($api.folderTemplateFileDelete,'delete','删除',{id:item.id})">mdi-delete-outline</v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="mt-2 pr-2 mb-5 max-width flex-justify-end">
      <pagination
        v-model="search.pageNo"
        :size="search.pageSize"
        :total="total"
        @input="loadList"
        @changePageSize="changePageSize"
      />
    </div>
    <v-dialog v-model="dialog.show" width="600">
      <v-card>
        <form @submit.prevent="submit()">
          <v-card-title class="font-weight-bold">重命名文件</v-card-title>
          <v-divider />
          <v-card-text class="py-5 body-1">
            <div class="flex-1 flex-column overflow-auto mt-7 px-12">
              <div class="mb-6 flex-y-center">
                <div class="form-title flex-0 flex-shrink-0 flex-justify-end mt-2">
                  <span class="red--text ml-1">*</span>文件名称：
                </div>
                <form-item
                  v-model="dialog.data.fileName"
                  class=" flex-1 mr-0"
                  placeholder="文件名称"
                  required
                  type="text"
                  @keyup="(e)=>{changeFolderName(e)}"
                />
                <span class=" flex-0 flex-shrink-0">{{ dialog.data.suffix }}</span>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <div class="max-width flex-y-center flex-justify-between">
              <div class="flex-1 flex-justify-end">
                <v-btn
                  width="80"
                  :loading="btnLoading"
                  class="mr-2"
                  depressed
                  height="30"
                  @click="dialog.show = false,dialog.data={}"
                >取消</v-btn>
                <v-btn
                  width="80"
                  type="submit"
                  :loading="btnLoading"
                  class
                  height="30"
                  color="primary"
                  depressed
                >确定</v-btn>
              </div>
            </div>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
export default {
  props: {
    folderId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0, // 权限总条数
      headers: [
        { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '文件名称', value: 'fileName' },
        { text: '上传时间', value: 'createTime' },
        { text: '操作', value: 'action' }
      ],
      list: [],
      listLoading: false,
      btnLoading: false,
      dialog: {
        show: false,
        data: {}
      }
    }
  },
  mounted() {
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.loadList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.loadList()
    },
    //
    loadList(e) {
      this.listLoading = true
      const param = new URLSearchParams()
      param.append('folderId', this.folderId)
      param.append('pageNo', this.search.pageNo)
      param.append('pageSize', this.search.pageSize)
      this.$http
        .get(this.$api.folderTemplateFileTemplateList, { data: param })
        .then(res => {
          this.list = (res.result && res.result.records) || []
          this.total = (res.result && res.result.total) || 0
          this.listLoading = false
        }).catch(() => {
          this.listLoading = false
        })
    },
    // 上传
    fileChange(e) {
      const _file = [...e.target.files]
      // console.log(_file)
      // const _index = 0
      // let _successNum = 0
      // let _errorNum = 0
      // let _fileNum = 0
      // let _err = ''
      // let _maxNum = 0
      let _hasMax = false
      let _hasFile = false
      let timeout = 0
      const form = new FormData()
      form.append('folderId', this.folderId)
      _file.forEach(item => {
        if (item.size > 200 * 1024 * 1024) {
          // _maxNum++
          // _errorNum++
          // _err += `<div>文件大小超过200M：${item.name}</div>`
          // if (_maxNum === _file.length) {
          //   let _text = `<div>上传成功文件数：${_successNum}</div>`
          //   _text += `<div>上传失败文件如下（${_errorNum}个）：</div>`
          //   this.$confirm({ text: _text + _err, title: '上传完成' })
          //     .then(vm => {
          //       vm.show = false
          //     }).catch(() => {})
          // }
          timeout = 1000
          _hasMax = true
        } else {
          _hasFile = true
          form.append('files', item)
          // _fileNum++
          // const form = new FormData()
          // form.append('folderId', this.folderId)
          // form.append('file', item)
          // this.btnLoading = true
          // this.$http.post(this.$api.folderTemplateFileTemplateUpload, { data: form }).then(() => {
          //   _index++
          //   _successNum++
          //   if (_index === _fileNum) {
          //     this.btnLoading = false
          //     this.$refs.upload.value = null // 重复上传同一文件失效问题
          //     if (_errorNum) {
          //       let _text = `<div>上传成功文件数：${_successNum}</div>`
          //       _text += `<div>上传失败文件如下（${_errorNum}个）：</div>`
          //       this.$confirm({ text: _text + _err, title: '上传完成' })
          //         .then(vm => {
          //           vm.show = false
          //         }).catch(() => {})
          //     } else {
          //       this.$message.success(`上传完成！上传成功文件数：${_successNum}`)
          //     }
          //     this.$emit('importOk')
          //     this.loadList()
          //   }
          // }).catch((res) => {
          //   // _err.push({ message: res.message })
          //   _err += `<div>${res.message}</div>`
          //   _index++
          //   _errorNum++
          //   if (_index === _fileNum) {
          //     this.btnLoading = false
          //     this.$refs.upload.value = null // 重复上传同一文件失效问题
          //     // if (_successNum) {
          //     //   this.$message.success(`上传完成！上传成功文件数：${_successNum}，上传失败文件数：${_errorNum} `)
          //     // } else {
          //     //   this.$message.error(`上传失败！`)
          //     // }
          //     let _text = `<div>上传成功文件数：${_successNum}</div>`
          //     _text += `<div>上传失败文件如下（${_errorNum}个）：</div>`
          //     this.$confirm({ text: _text + _err, title: '上传完成' })
          //       .then(vm => {
          //         vm.show = false
          //       }).catch(() => {})
          //     // this.$emit('importOk')
          //     this.loadList()
          //   } else {
          //     return this.$message
          //   }
          // })
        }
      })
      this.$refs.upload.value = null // 重复上传同一文件失效问题
      this.$nextTick(() => {
        if (_hasMax) {
          this.$message.error('文件大小不能超过200M！')
        }
        if (_hasFile) {
          this.btnLoading = true
          setTimeout(() => {
            this.$http.post(this.$api.folderTemplateFileTemplateUpload, { data: form }).then((res) => {
              this.btnLoading = false
              this.$emit('importOk')
              this.loadList()
              this.$message.success(res.message)
            }).catch((res) => {
              this.btnLoading = false
            })
          }, timeout)
        }
      })
    },
    changeFolderName(e) {
      if (e) {
        // this.$set(this.dialog.data, 'fileName', e.replace(/\s*/g, ''))
        this.$set(this.dialog.data, 'fileName', e.replace(/[:"<>/?\\\\*|]/g, ''))
        // this.$set(this[type],'folderName',e.replace(/[:"<>/?\\\\*|]/g,""))
      }
      this.$forceUpdate()
    },
    // 确定重命名
    submit() {
      const param = new URLSearchParams()
      param.append('fileId', this.dialog.data.fileId)
      param.append('fileName', this.dialog.data.fileName + this.dialog.data.suffix)
      this.btnLoading = true
      this.$http
        .post(this.$api.folderTemplateFileRename, { data: param })
        .then(res => {
          this.$message.success(res.message)
          this.dialog.show = false
          this.btnLoading = false
          this.loadList()
        }).catch(() => {
          this.btnLoading = false
        })
    },
    // 编辑 弹窗
    edit(item) {
      const suffixIndex = item.fileName.lastIndexOf('.')
      this.dialog.data = {
        fileId: item.id,
        fileName: suffixIndex !== -1 ? item.fileName.substring(0, suffixIndex) : item.fileName,
        suffix: suffixIndex !== -1 ? item.fileName.substring(suffixIndex) : ''
      }
      this.dialog.show = true
    },
    // 下载附件
    down(id) {
      // this.$http
      //   .get(this.$api.folderTemplateFileDownload, { data: { id: id }})
      //   .then(res => {
      //     this.$message.success(res.message)
      //   }).catch(() => {
      //   })
      const _this = this
      _this.$http.blobGet(this.$api.folderTemplateFileDownload, { data: { id: id }}).then((_r) => {
        const res = _r.data
        const r = new FileReader()
        r.onload = function() {
          try {
            JSON.parse(this.result)
            if ([406, 407, 408].includes(parseInt(JSON.parse(this.result).code))) {
              localStorage.removeItem('access_token')
              localStorage.removeItem('userInfo')
              _this.$message.error(JSON.parse(this.result).message || '请稍后重试')
              _this.$router.replace({ path: '/login' })
            } else {
              _this.$message.error('下载失败！')
            }
          } catch (err) {
            const _res = res
            // const blob = new Blob([_res])
            const blob = new Blob([_res], { type: 'application/octet-stream' })
            const href = window.URL.createObjectURL(blob)
            const downloadElement = document.createElement('a')
            // 创建下载的链接
            downloadElement.href = href
            downloadElement.download = (decodeURI(_r.headers['content-disposition'].slice(_r.headers['content-disposition'].indexOf('filename=') + 9))) // 下载后文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() // 点击下载
            document.body.removeChild(downloadElement) // 下载完成移除元素
            window.URL.revokeObjectURL(href) // 释放掉blob对象
            _this.$forceUpdate()
            _this.$message.success('下载完成！')
          }
        }
        r.readAsText(res)
      }).catch(() => {
      })
    },
    activeList(url, method, title, params) {
      this.$confirm({ text: '是否确认' + title + '？' })
        .then(vm => {
          const param = new URLSearchParams()
          for (const i in params) {
            param.append(i, params[i] || '')
          }
          vm.loading = true
          this.$http[method](url, { data: param })
            .then(res => {
              vm.show = false
              vm.loading = false
              this.$message.success(res.message)
              this.initList()
            })
            .catch(() => {
              vm.show = false
              vm.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>

<style>

</style>
